<template>
    <main class="supplier-payment-confirmation supplier-payment-confirmation-185 page_bg_grey">

        <section class="payment-caption-section">

            <div class="payment-caption-container">

                <div class="payment-caption-wrapper payment-product-name">
                    <span v-if="supplierData && supplierData.name">{{ supplierData.name }}</span>
                </div>

                <div class="payment-caption-wrapper text-center">
                    <img class="supplier-logo" v-if="supplierData && supplierData.image" :src="supplierData.image" />
                </div>

                <div class="payment-caption-wrapper">
                    &nbsp;
                </div>

            </div>

        </section>

        <section class="payment-confirmation-data-section">

            <div class="payment-confirmation-data-top-block">
                <span class="payment-confirmation-data-top-block-text">אימות פרטי לקוח לפני ביצוע הטעינה</span>
            </div>

            <div class="payment-confirmation-data">

                <div class="payment-confirmation-data-block">

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">שם העסק:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ businessName }}</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">מספר חוזה:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ contractNumber }}</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">סכום חשבונית:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ price }}</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">סכום לתשלום:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ price }}</div>
                    </div>

                </div>

            </div>

            <div class="payment-confirmation-data-bottom-block">

                <div class="payment-confirmation-data-bottom-block-text"><span class="red-text">לאחר אימות הפרטים לא ניתן לבטל את העסקה!</span></div>

                <div class="payment-confirmation-data-bottom-block-button">
                    <a class="link payment-button" @click="handleSupplierPaymentConfirmationSubmit">אישור פרטים וביצוע רכישה</a>
                </div>

                <div class="payment-confirmation-data-bottom-block-text"><span class="red-text">שימו לב!! לפי הנחיות חברת החשמל, אין לחייב את הלקוח בעמלה</span></div>

                <div class="payment-confirmation-data-bottom-block-text"><span class="">בסיום התשלום צריך לספק ללקוח קבלה בלבד!</span></div>

            </div>

        </section>

    </main>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import appConfig from '../../../appConfig'

    export default {
        name: 'SupplierPaymentConfirmation185',
        props: {
            msg: String
        },
        data() {
            return {
                currentDate: new Date()
            }
        },
        mounted() {
        },
        computed: {
            ...mapState({
                current_payment_data: state => state.supplier_payment.current_payment_data,
                supplier_items: state => state.supplier.supplier_items,
                suppliers: state => state.supplier.suppliers,
                user_details: state => state.account.user_details && state.account.user_details.hasOwnProperty('details') ? state.account.user_details.details : null
            }),
            supplierId() {
                return this.$route.params.supplier_id
            },
            supplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.supplierId) {
                                supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.supplierId in this.suppliers) {
                    supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
                }

                let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
                return supplierData ? supplierData : null
            },
            paymentData() {
                let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

                return paymentData
            },
            contractNumber() {
                return this.paymentData && this.paymentData.hasOwnProperty('contractNumber') ? this.paymentData.contractNumber : ''
            },
            price() {
                return this.paymentData && this.paymentData.hasOwnProperty('price') ? this.paymentData.price : ''
            },
            itemId() {
                return this.paymentData && this.paymentData.hasOwnProperty('itemId') ? this.paymentData.itemId : ''
            },
            phoneNumber() {
                return this.paymentData && this.paymentData.hasOwnProperty('phoneNumber') ? this.paymentData.phoneNumber : ''
            },
            address() {
                return this.paymentData && this.paymentData.hasOwnProperty('address') ? this.paymentData.address : ''
            },
            invoice() {
                return this.paymentData && this.paymentData.hasOwnProperty('invoice') ? this.paymentData.invoice : ''
            },
            name() {
                return this.paymentData && this.paymentData.hasOwnProperty('name') ? this.paymentData.name : ''
            },
            businessName() {
                return this.user_details && this.user_details.businessName ? this.user_details.businessName : ''
            },
            currentDateString() {
                let currentDateString = this.currentDate.getDate().toString().padStart(2, '0') + '/' + (this.currentDate.getMonth()+1).toString().padStart(2, '0') + '/' + this.currentDate.getFullYear()

                return currentDateString
            },
            currentTimeString() {
                let currentTimeString = this.currentDate.getHours().toString().padStart(2, '0') + ":" + this.currentDate.getMinutes().toString().padStart(2, '0') + ":" + this.currentDate.getSeconds().toString().padStart(2, '0')

                return currentTimeString
            }
        },
        created() {
        },
        methods: {
            ...mapActions('supplier_payment', {
                supplierPayment: 'supplierPayment'
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            handleSupplierPaymentConfirmationSubmit() {
                const supplierId = this.supplierId
                const itemId = this.itemId
                const contractNumber = this.contractNumber
                const phoneNumber = this.phoneNumber
                const price = this.price
                const address = this.address
                const invoice = this.invoice
                const name = this.name

                if (supplierId != '' && itemId != '' && contractNumber != ''
                    && phoneNumber != '' && price != '') {
                    this.supplierPayment(
                        {
                            supplier_id: supplierId,
                            payment_data: {
                                itemId: itemId,
                                contractNumber: contractNumber,
                                phoneNumber: phoneNumber,
                                price: price,
                                address: address,
                                invoice: invoice,
                                name: name
                            },
                        })
                        .then(
                            result_data => {
                            },
                            error => {
                            }
                        );
                }
            }
        },
        updated: function () {
        },
    }
</script>

<style>
</style>