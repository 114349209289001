<template>
    <div class="w-100 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
        <SupplierPaymentConfirmation187 v-if="supplierId == 187"></SupplierPaymentConfirmation187>
        <SupplierPaymentConfirmation185 v-else-if="supplierId == 185"></SupplierPaymentConfirmation185>
        <SupplierPaymentConfirmation192 v-else-if="supplierId == 192"></SupplierPaymentConfirmation192>
        <SupplierPaymentConfirmation999 v-else-if="supplierId == 999"></SupplierPaymentConfirmation999>
        <SupplierPaymentConfirmation194 v-else-if="supplierId == 194"></SupplierPaymentConfirmation194>
        <SupplierPaymentConfirmation186 v-else-if="supplierId == 186"></SupplierPaymentConfirmation186>
        <SupplierPaymentConfirmation193 v-else-if="supplierId == 193"></SupplierPaymentConfirmation193>
        <SupplierPaymentConfirmation212 v-else-if="supplierId == '212'"></SupplierPaymentConfirmation212>
        <SupplierPaymentConfirmation213 v-else-if="supplierId == '213'"></SupplierPaymentConfirmation213>
        <SupplierPaymentConfirmation1013 v-else-if="supplierId == 1013"></SupplierPaymentConfirmation1013>
        <SupplierPaymentConfirmation1019 v-else-if="supplierId == 1019"></SupplierPaymentConfirmation1019>
        <main v-else class="supplier-payment-confirmation page_bg_grey">
            <div class="text-center">Payment Confirmation Page, sorry, we are developing this page yet...</div>
        </main>

        <loading :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import SupplierPaymentConfirmation187 from '../components/supplier_payments/187/SupplierPaymentConfirmation187'
    import SupplierPaymentConfirmation185 from '../components/supplier_payments/185/SupplierPaymentConfirmation185'
    import SupplierPaymentConfirmation192 from '../components/supplier_payments/192/SupplierPaymentConfirmation192'
    import SupplierPaymentConfirmation999 from '../components/supplier_payments/999/SupplierPaymentConfirmation999'
    import SupplierPaymentConfirmation194 from '../components/supplier_payments/194/SupplierPaymentConfirmation194'
    import SupplierPaymentConfirmation186 from '../components/supplier_payments/186/SupplierPaymentConfirmation186'
    import SupplierPaymentConfirmation193 from '../components/supplier_payments/193/SupplierPaymentConfirmation193'
    import SupplierPaymentConfirmation212 from '../components/supplier_payments/212/SupplierPaymentConfirmation212'
    import SupplierPaymentConfirmation213 from '../components/supplier_payments/213/SupplierPaymentConfirmation213'
    import SupplierPaymentConfirmation1013 from '../components/supplier_payments/1013/SupplierPaymentConfirmation1013'
    import SupplierPaymentConfirmation1019 from '../components/supplier_payments/1019/SupplierPaymentConfirmation1019'
    export default {
        name: 'SupplierPaymentConfirmation',
        components: {
            Loading,
            SupplierPaymentConfirmation187,
            SupplierPaymentConfirmation185,
            SupplierPaymentConfirmation192,
            SupplierPaymentConfirmation999,
            SupplierPaymentConfirmation194,
            SupplierPaymentConfirmation186,
            SupplierPaymentConfirmation193,
            SupplierPaymentConfirmation212,
            SupplierPaymentConfirmation213,
            SupplierPaymentConfirmation1013,
            SupplierPaymentConfirmation1019
        },
        data: () => {
            return {
                loading: false
            }
        },
        computed: {
            ...mapState({
                current_payment_data: state => state.supplier_payment.current_payment_data
            }),
            ...mapState({
                supplierPaymentInProgress: state => state.supplier_payment.supplierPaymentInProgress,
                getSupplierPaymentDetailsInProgress: state => state.supplier_payment.getSupplierPaymentDetailsInProgress
            }),
            supplierId() {
                return this.$route.params.supplier_id
            },
            paymentData() {
                let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

                return paymentData
            }
        },
        created() {
            if (this.paymentData) {
                this.getSupplier({'supplier_id': this.supplierId})
            } else {
                this.$router.push({name: 'SupplierPayment', params: {supplier_id: this.supplierId}})
            }
        },
        mounted() {},
        watch: {
            supplierPaymentInProgress(inProgress) {
                this.loading = inProgress || this.getSupplierPaymentDetailsInProgress;
            },
            getSupplierPaymentDetailsInProgress(inProgress) {
                this.loading = inProgress || this.supplierPaymentInProgress;
            }
        },
        methods: {
            ...mapActions('supplier', {
                getSupplier: 'getSupplier'
            }),
        }
    }
</script>