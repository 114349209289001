<template>
  <main class="supplier-payment supplier-payment-1019 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("Payment 6 crosses north") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/6North.png"
          />
        </div>

        <div class="payment-caption-wrapper">&nbsp;</div>
      </div>
    </section>

    <section class="payment-form-section">
      <div class="payment-confirmation-data">

        <div v-if="options && options.length" class="payment-confirmation-data-block">
          <div class="text-bold">
            <a class="link select-all" @click="handleSelectAllClick">{{ $t("SUPPLIER_PAYMENT.SELECT_ALL") }}</a>
          </div>
          <div class="">
            <div class="payment-option-row" v-for="(option, index) in options">
              <div class="option-checkbox">
                <v-checkbox :id="option.id" :value="option" name="option"  v-model="selectedOptions" color="#0D3856" class="cus-checkbox"></v-checkbox>
              </div>
              <div class="option-delimiter"></div>
              <div class="option-description">
                <label :for="option.id"><span>{{ option.description }}</span> <span class="text-bold">{{ option.car_number }}</span> - <span class="text-bold">{{ option.price + option.currency }}</span></label>
              </div>
            </div>
            <div v-if="finalCommission && finalCommission > 0" class="payment-option-row">
              <div class="option-checkbox">
                <img src="/assets/img/icons/exclamation-triangle-light.svg"/>
              </div>

              <div class="option-delimiter"></div>
              <div class="option-description commission-description" v-html="commissionDescription"></div>
            </div>
          </div>
          <div class="">
            <div class="payment-option-row total-price-row">
                <div v-html="totalPriceDescription"></div>
            </div>
          </div>

          <div class="confirm-action mt-15">
            <button class="primary-btn finish-btn" @click="handleSupplierPaymentConfirmationSubmit">
              {{ $t("Finished and closed") }}
            </button>
          </div>
        </div>
        <div v-else class="text-center">
            {{ $t("Something went wrong, please contact support or try again later") }}
        </div>
      </div>
    </section>
  </main>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import appConfig from '../../../appConfig'

    export default {
        name: 'SupplierPaymentConfirmation1019',
        props: {
            msg: String
        },
        data() {
            return {
                currentDate: new Date(),
                selectedOptions: []
            }
        },
        mounted() {
        },
        computed: {
            ...mapState({
                current_payment_data: state => state.supplier_payment.current_payment_data,
                supplier_items: state => state.supplier.supplier_items,
                suppliers: state => state.supplier.suppliers,
                user_details: state => state.account.user_details && state.account.user_details.hasOwnProperty('details') ? state.account.user_details.details : null
            }),
            supplierId() {
                return this.$route.params.supplier_id
            },
            supplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.supplierId) {
                                supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.supplierId in this.suppliers) {
                    supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
                }

                let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
                return supplierData ? supplierData : null
            },
            paymentData() {
                let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

                return paymentData
            },
            carNumber() {
                return this.paymentData && this.paymentData.hasOwnProperty('carNumber') ? this.paymentData.carNumber : ''
            },
            license() {
                return this.paymentData && this.paymentData.hasOwnProperty('license') ? this.paymentData.license : ''
            },
            finalCommission() {
                return this.paymentData && this.paymentData.hasOwnProperty('finalCommission') ? this.paymentData.finalCommission : ''
            },
            itemId() {
                return this.paymentData && this.paymentData.hasOwnProperty('itemId') ? this.paymentData.itemId : ''
            },
            phoneNumber() {
                return this.paymentData && this.paymentData.hasOwnProperty('phoneNumber') ? this.paymentData.phoneNumber : ''
            },
            options() {
                return this.paymentData && this.paymentData.hasOwnProperty('options') ? this.paymentData.options : ''
            },
            businessName() {
                return this.user_details && this.user_details.businessName ? this.user_details.businessName : ''
            },
            currentDateString() {
                let currentDateString = this.currentDate.getDate().toString().padStart(2, '0') + '/' + (this.currentDate.getMonth()+1).toString().padStart(2, '0') + '/' + this.currentDate.getFullYear()

                return currentDateString
            },
            currentTimeString() {
                let currentTimeString = this.currentDate.getHours().toString().padStart(2, '0') + ":" + this.currentDate.getMinutes().toString().padStart(2, '0') + ":" + this.currentDate.getSeconds().toString().padStart(2, '0')

                return currentTimeString
            },
            selectedOptionsCount() {
                return this.selectedOptions.length || 0;
            },
            selectedOptionsPrice() {
                return Math.round(((this.selectedOptions.reduce((a, b) => parseFloat(a) + (parseFloat(b['price']) || 0), 0)) + Number.EPSILON) * 100) / 100 || 0;
            },
            totalPrice() {
                return Math.round(((this.selectedOptionsPrice + this.selectedOptionsCount * parseFloat(this.finalCommission)) + Number.EPSILON) * 100) / 100 || 0;
            },
            commissionDescription() {
               let commissionAmountText = '<span class="text-bold">' + this.finalCommission + '₪</span>';
               return this.$t("There is a commission of [commission_amount_text] for each invoice").indexOf('[commission_amount_text]') !== -1
                   ? this.$t("There is a commission of [commission_amount_text] for each invoice").replace('[commission_amount_text]', commissionAmountText)
                   : this.$t("There is a commission of [commission_amount_text] for each invoice") + ' ' + commissionAmountText;
            },
            totalPriceDescription() {
              let selectedOptionsCountText = '<span class="text-bold">' + this.selectedOptionsCount + ' ' + this.$t('Invoices').toLowerCase() + '</span>';
              let totalPriceText = '<span class="text-bold">' + this.$t('Total payment') + ' ' + this.totalPrice +'₪</span>';

               let totalPriceDescription = this.$t("[selected_options_count_text] were selected for a total amount of [selected_options_price] - [total_price_text]").indexOf('[selected_options_count_text]') !== -1
                   ? this.$t("[selected_options_count_text] were selected for a total amount of [selected_options_price] - [total_price_text]").replace('[selected_options_count_text]', selectedOptionsCountText)
                   : this.$t("[selected_options_count_text] were selected for a total amount of [selected_options_price] - [total_price_text]") + ' ' + selectedOptionsCountText;
               totalPriceDescription = totalPriceDescription.indexOf('[selected_options_price]') !== -1
                   ? totalPriceDescription.replace('[selected_options_price]', this.selectedOptionsPrice)
                   : totalPriceDescription + ' ' + this.selectedOptionsPrice;
               totalPriceDescription = totalPriceDescription.indexOf('[total_price_text]') !== -1
                   ? totalPriceDescription.replace('[total_price_text]', totalPriceText)
                   : totalPriceDescription + ' ' + totalPriceText;

               return totalPriceDescription;
            }
        },
        methods: {
            ...mapActions('supplier_payment', {
                supplierPayment: 'supplierPayment'
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            handleSupplierPaymentConfirmationSubmit() {
                const supplierId = this.supplierId
                const itemId = this.itemId
                const carNumber = this.carNumber
                const license = this.license
                const price = this.selectedOptionsPrice
                const totalPrice = this.totalPrice
                const phoneNumber = this.phoneNumber
                const selectedOptionsIds = this.selectedOptions.map(selectedOption => selectedOption.id)

                if (supplierId != '' && itemId != '' && carNumber != ''
                    && phoneNumber != '' && license != '' && price != '' && totalPrice != ''
                    && selectedOptionsIds.length) {
                    this.supplierPayment(
                        {
                            supplier_id: supplierId,
                            payment_data: {
                                itemId: itemId,
                                car_number: carNumber,
                                license: license,
                                phoneNumber: phoneNumber,
                                price: price,
                                totalPrice: totalPrice,
                                option_ids: selectedOptionsIds
                            },
                        })
                        .then(
                            result_data => {
                            },
                            error => {
                            }
                        );
                }
            },
            handleSelectAllClick() {
                if (this.selectedOptions.length === this.options.length) {
                    this.selectedOptions = [];
                } else {
                    this.selectedOptions = JSON.parse(JSON.stringify(this.options));
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
  .supplier-payment-1019 {
    .link.select-all, label {
      cursor: pointer;
    }
    .link.select-all {
      color: #5aceff;
      text-decoration: underline;
    }
    .confirm-action {
      button {
        margin: auto;
      }
    }
    .payment-confirmation-data {
      max-width: 1130px;
    }
    .total-price-row {
      line-height: 50px;
      font-size: 22px;
      background-color: white;
      padding: 0 13px;
      color: #0071bc;
    }
    .payment-confirmation-data-block {
      font-size: 18px;
      line-height: 24px;
    }

    .payment-option-row {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      margin: 10px 0;
      line-height: 40px;
      .option-checkbox {
        width: 40px;
        img {
          vertical-align: middle;
        }
      }
      .option-delimiter {
        width: 20px;
      }
      .option-description {
        width: calc(100% - 20px - 40px);
        background-color: white;
        padding: 0 12px;
      }
      .option-description.commission-description {
        background-color: transparent;
        color: #0071bc;
        font-size: 22px;
      }
    }
  }
</style>