<template>
    <main class="supplier-payment-confirmation supplier-payment-confirmation-1013 page_bg_grey">

        <section class="payment-caption-section">

            <div class="payment-caption-container">

                <div class="payment-caption-wrapper payment-product-name">
                    <span>{{ $t("Purchase a power charging code") }}</span>
                </div>

                <div class="payment-caption-wrapper text-center">
                    <img
                        class="supplier-logo"
                        src="/assets/img/logo/n-logo.png"
                    />
                </div>

                <div class="payment-caption-wrapper">
                    &nbsp;
                </div>

            </div>

        </section>

        <section class="payment-confirmation-data-section">

            <div class="payment-confirmation-data-top-block">
                <span class="payment-confirmation-data-top-block-text">{{ $t("Verification of customer information before loading") }}</span>
            </div>

            <div class="payment-confirmation-data">

                <div class="payment-confirmation-data-block">
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("Contract number") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ contractNumber }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("Meter number") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ meter_number }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("Date of payment") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ currentDateString }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("Address") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ address }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("Purchase amount") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ purchased_amount }} ₪</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("Amount to debts") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ reduced_amount }} ₪</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("Total net amount") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ net_amount }} ₪</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("Commission") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ finalCommission }} ₪</div>
                    </div>
                    <div class="payment-confirmation-data-block-row total-price">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("Total Price") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ price }} ₪</div>
                    </div>
                </div>
            </div>

            <div class="payment-confirmation-data-bottom-block">

                <div class="payment-confirmation-data-bottom-block-text"><span class="red-text">{{ $t("After verifying the details the transaction can not be canceled!") }}</span></div>

                <div class="payment-confirmation-data-bottom-block-button">
                    <a class="link payment-button" @click="handleSupplierPaymentConfirmationSubmit">{{ $t("Confirmation of details and purchase") }}</a>
                </div>

                <div class="payment-form-section-bottom-block mt-10">
                    <span class="payment-confirmation-data-bottom-block-text">{{ $t("At the end of the payment need to give client receipt") }}</span>
                </div>
            </div>

        </section>

    </main>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import appConfig from '../../../appConfig'

export default {
    name: 'SupplierPaymentConfirmation1013',
    props: {
        msg: String
    },
    data() {
        return {
            currentDate: new Date()
        }
    },
    mounted() {
    },
    computed: {
        ...mapState({
            current_payment_data: state => state.supplier_payment.current_payment_data,
            supplier_items: state => state.supplier.supplier_items,
            suppliers: state => state.supplier.suppliers,
            user_details: state => state.account.user_details && state.account.user_details.hasOwnProperty('details') ? state.account.user_details.details : null
        }),
        supplierId() {
            return this.$route.params.supplier_id
        },
        supplierData() {
            let supplierDataFromSuppliersItemsData = null;
            if (this.supplier_items) {
                for (let supplier_type in this.supplier_items) {
                    for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                        if (suppliers_type_item.id == this.supplierId) {
                            supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                            break;
                        }
                    }
                }
            }

            let supplierDataFromSuppliersData = null;
            if (this.suppliers && this.supplierId in this.suppliers) {
                supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
            }

            let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
            return supplierData ? supplierData : null
        },
        paymentData() {
            let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

            return paymentData
        },
        contractNumber() {
            return this.paymentData && this.paymentData.hasOwnProperty('contractNumber') ? this.paymentData.contractNumber : ''
        },
        sumToPay() {
            return this.paymentData && this.paymentData.hasOwnProperty('sumToPay') ? this.paymentData.sumToPay : ''
        },
        finalCommission() {
            return this.paymentData && this.paymentData.hasOwnProperty('finalCommission') ? this.paymentData.finalCommission : ''
        },
        price() {
            return this.paymentData && this.paymentData.hasOwnProperty('price') ? this.paymentData.price : ''
        },
        address() {
            return this.paymentData && this.paymentData.hasOwnProperty('address') ? this.paymentData.address : ''
        },
        purchased_amount() {
            return this.paymentData && this.paymentData.hasOwnProperty('purchased_amount') ? this.paymentData.purchased_amount : ''
        },
        net_amount() {
            return this.paymentData && this.paymentData.hasOwnProperty('net_amount') ? this.paymentData.net_amount : ''
        },
        reduced_amount() {
            return this.paymentData && this.paymentData.hasOwnProperty('reduced_amount') ? this.paymentData.reduced_amount : ''
        },
        itemId() {
            return this.paymentData && this.paymentData.hasOwnProperty('itemId') ? this.paymentData.itemId : ''
        },
        phoneNumber() {
            return this.paymentData && this.paymentData.hasOwnProperty('phoneNumber') ? this.paymentData.phoneNumber : ''
        },
        uuid() {
            return this.paymentData && this.paymentData.hasOwnProperty('uuid') ? this.paymentData.uuid : ''
        },
        meter_number() {
            return this.paymentData && this.paymentData.hasOwnProperty('meter_number') ? this.paymentData.meter_number : ''
        },
        businessName() {
            return this.user_details && this.user_details.businessName ? this.user_details.businessName : ''
        },
        currentDateString() {
            let currentDateString = this.currentDate.getDate().toString().padStart(2, '0') + '/' + (this.currentDate.getMonth()+1).toString().padStart(2, '0') + '/' + this.currentDate.getFullYear()

            return currentDateString
        },
        currentTimeString() {
            let currentTimeString = this.currentDate.getHours().toString().padStart(2, '0') + ":" + this.currentDate.getMinutes().toString().padStart(2, '0') + ":" + this.currentDate.getSeconds().toString().padStart(2, '0')

            return currentTimeString
        }
    },
    methods: {
        ...mapActions('supplier_payment', {
            supplierPayment: 'supplierPayment'
        }),
        getApiURL() {
            return appConfig.apiUrl
        },
        handleSupplierPaymentConfirmationSubmit() {
            const supplierId = this.supplierId
            const itemId = this.itemId
            const contractNumber = this.contractNumber
            const sumToPay = this.sumToPay
            const price = this.price
            const phoneNumber = this.phoneNumber
            const uuid = this.uuid

            if (supplierId != '' && itemId != '' && contractNumber != ''
                && phoneNumber != '' && sumToPay != '' && price != '' && uuid != '') {
                this.supplierPayment(
                    {
                        supplier_id: supplierId,
                        payment_data: {
                            itemId: itemId,
                            contractNumber: contractNumber,
                            phoneNumber: phoneNumber,
                            price: sumToPay,
                            uuid: uuid
                        },
                    })
                    .then(
                        result_data => {
                        },
                        error => {
                        }
                    );
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .supplier-payment-confirmation-1013 {
        .supplier-logo {
            max-width: 100px;
        }
        .payment-product-name,
        .payment-confirmation-data-top-block-text,
        .payment-confirmation-data-bottom-block-text {
            font-size: 30px;
        }
        .payment-confirmation-data-block-row, 
        .payment-success-data-block-2-columns .payment-success-data-block-row {
            font-size: 20px;
            margin: 5px 0;
        }
        .payment-confirmation-data-block-row.total-price>div {
            font-size: 30px;
            color: #F93959 !important;
            font-weight: bold;
        }
        .payment-confirmation-data {
            max-width: 800px;
            padding: 0;
        }
        .payment-confirmation-data-block-row>div {
            white-space: normal;
        }
        .payment-confirmation-data-block-row-label {
            width: calc(50% - 1px);
            /*min-width: 430px;*/
        }
        .payment-confirmation-data-block-row-data {
            width: unset;
            width: calc(50% - 1px);
        }
    }
    .ltr-type {
        .payment-form {
           text-align: left; 
        }
        .input-tooltip-container {
            left: unset;
            right: 0;
        }
        .payment-form-with-tooltips .payment_field_wrapper .input {
            margin-left: 0;
            margin-right: 12px;
        }
        .payment-confirmation-data-block-row>div {
            text-align: left;
        }
    }
    @media screen and (max-width: 600px) {
        .payment-confirmation-data-block-row-label, 
        .payment-confirmation-data-block-row-data, 
        .payment-success-data-block-2-columns .payment-success-data-block-row-label, 
        .payment-success-data-block-2-columns .payment-success-data-block-row-data {
            display: block;
            width: 100%;
        }
    }
</style>