<template>
  <main class="supplier-payment supplier-payment-confirmation-police page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("Payment of police reports") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/police.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-end pt-5">
          <a class="primary-btn bg-2 cancel-btn pointer cancel-btn">
            {{ $t("Cancelation") }}
          </a>
        </div>
      </div>
    </section>
    <section class="main-content">
      <p class="weight-700 mb-7">{{ $t("3 open reports for payment were found in the police database") }}</p>
      <div class="police-table bg-white">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="policeData"
          item-key="debt"
          show-select
          class="cus-table"
        >
          <template v-slot:[`item.paymentSum`]="{ item }">
            <span class="color-red3">
              {{ item.paymentSum }}
            </span>
          </template>
        </v-data-table>
      </div>
      <div class="police-form">
        <div class="inline-form_wrap">
          <p>{{ $t("Number of selected reports") }}: </p>
          <input type="text" class="input">
        </div>
        <div class="inline-form_wrap">
          <p>{{ $t("Total sum of accounts") }}: </p>
          <input type="text" class="input">
        </div>
        <div class="inline-form_wrap">
          <p>{{ $t("Service fee") }}: </p>
          <input type="text" class="input">
        </div>
        <div class="inline-form_wrap">
          <p class="color-red3 weight-700">{{ $t("Total payment") }}: </p>
          <input type="text" class="input">
        </div>
        <div class="form_wrap mt-10">
          <p class="mb-2 fs16">{{ $t("Mobile phone number for reception") }}</p>
          <input type="text" class="input">
        </div>
        <div class="d-flex mt-5">
          <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
          <label for="" class="fs16">
            <b class="color-red3">{{ $t("Note ! The customer is aware") }} </b>
            {{ $t("That if there is a debt of the type of compensation for the victim of an offense in a criminal proceeding, any amount paid will first be credited in favor of covering this debt") }}
          </label>
        </div>
        <button class="primary-btn finish-btn mt-10">
          {{ $t("Whole") }}
        </button>
      </div>
      <div class="police-footer mt-10">
        <p class="weight-700 fs30 color-blue1">{{ $t("At the end of the payment need to give client receipt") }}</p>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "SupplierPaymentConfirmation212",
  props: {
    msg: String,
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: this.$t("Central portfolio tax / voucher tax in the arrangement"),
          value: 'voucherTax',
          align: "center"
        },
        { 
          text: this.$t("Debt file factor factor"), 
          value: 'debt', 
          align: "center"
        },
        { 
          text: this.$t("Date of payment"), 
          value: 'date',
          align: "center" 
        },
        { 
          text: this.$t("Foundation"), 
          value: 'foundation',
          align: "center" 
        },
        { 
          text: this.$t("Arrears and expenses"), 
          value: 'arrears',
          align: "center" 
        },
        { 
          text: this.$t("Payment sum"), 
          value: 'paymentSum',
          align: "center" 
        }
      ],
      policeData: [
        {
          voucherTax: 'ד-5543345',
          debt: 90515640291,
          date: '23/06/2020',
          foundation: 250,
          arrears: 0,
          paymentSum: 250,
        },
        {
          voucherTax: 'ד-5543345',
          debt: 90456640291,
          date: '23/06/2020',
          foundation: 250,
          arrears: 0,
          paymentSum: 250,
        },
        {
          voucherTax: 'ד-5543345',
          debt: 90515645691,
          date: '23/06/2020',
          foundation: 250,
          arrears: 0,
          paymentSum: 250,
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.supplier-payment-confirmation-police {
  .payment-caption-container {
    align-items: center;
    .payment-caption-wrapper {
      margin-top: 0;
    }
    .supplier-logo {
      max-width: 120px;
      min-width: unset;
    }
    .payment-product-name {
      font-size: 30px;
    }
    .cancel-btn {
      max-width: 150px;
    }
  }
  .main-content {
    margin: 0 auto;
    max-width: 1150px;
    margin-top: 30px;
    color: #002036;
    .police-table {
      padding: 10px 0;
    }
    .police-form {
      max-width: 500px;
      margin: 0 auto;
      margin-top: 30px;
      .inline-form_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        p {
          width: 50%;
          padding: 0 10px;
          font-size: 20px;
        }
        .input {
          width: 50%;
          padding: 0 10px;
          height: 35px;
        }
      }
      .cus-checkbox {
        margin-top: 7px;
        margin-left: 10px;
      }
    }
    .police-footer {
      text-align: center;
      .primary-btn {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>